import revive_payload_client_dhYDFg9gKU from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_eslint@9.9.1_typescript@5.5.4_vite@5.4.3/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_Yk5yNYOgV2 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_eslint@9.9.1_typescript@5.5.4_vite@5.4.3/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_oPLwuBDAWF from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_eslint@9.9.1_typescript@5.5.4_vite@5.4.3/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_tMMPuIJuI9 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_eslint@9.9.1_typescript@5.5.4_vite@5.4.3/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_CMYspViGoP from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_eslint@9.9.1_typescript@5.5.4_vite@5.4.3/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_AixbKMmlbR from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_eslint@9.9.1_typescript@5.5.4_vite@5.4.3/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_aKMArMRYGp from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_eslint@9.9.1_typescript@5.5.4_vite@5.4.3/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_BAgSmKI7Te from "/opt/build/repo/node_modules/.pnpm/@pinia+nuxt@0.5.4_typescript@5.5.4_vue@3.5.1/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_lBUw1fYxih from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_eslint@9.9.1_typescript@5.5.4_vite@5.4.3/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import notiwind_qYGIDwLcpw from "/opt/build/repo/plugins/notiwind.ts";
import sentry_client_shVUlIjFLk from "/opt/build/repo/plugins/sentry.client.ts";
import koala_client_WKDdOaEvL9 from "/opt/build/repo/plugins/koala.client.ts";
export default [
  revive_payload_client_dhYDFg9gKU,
  unhead_Yk5yNYOgV2,
  router_oPLwuBDAWF,
  payload_client_tMMPuIJuI9,
  navigation_repaint_client_CMYspViGoP,
  check_outdated_build_client_AixbKMmlbR,
  chunk_reload_client_aKMArMRYGp,
  plugin_vue3_BAgSmKI7Te,
  components_plugin_KR1HBZs4kY,
  prefetch_client_lBUw1fYxih,
  notiwind_qYGIDwLcpw,
  sentry_client_shVUlIjFLk,
  koala_client_WKDdOaEvL9
]