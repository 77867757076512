import { defineNuxtPlugin } from "#app"
import Notifications, { notify } from "notiwind"

type NotificationOptions = {
  group?: string
  title: string
  text: string
  type?: "info" | "success" | "warn" | "error"
}

// core method, use with $toast("foo")
function notifyFlexible(
  info: string | NotificationOptions,
  displayFor?: number,
  type?: "info" | "success" | "warn" | "error"
) {
  if (typeof info === "string") {
    notify({ title: info, type }, displayFor)
  } else {
    notify(info, displayFor)
  }
}

// convenience handlers: $toast.success("foo"), $toast.error("foo")

notifyFlexible.success = (
  info: string | NotificationOptions,
  displayFor?: number
) => {
  if (typeof info === "string") {
    notify({ title: info, type: "success" }, displayFor)
  } else {
    info.type = "success"
    notify(info, displayFor)
  }
}

notifyFlexible.warn = (
  info: string | NotificationOptions,
  displayFor?: number
) => {
  if (typeof info === "string") {
    notify({ title: info, type: "warn" }, displayFor)
  } else {
    info.type = "warn"
    notify(info, displayFor)
  }
}

notifyFlexible.error = (
  info: string | NotificationOptions,
  displayFor?: number
) => {
  if (typeof info === "string") {
    notify({ title: info, type: "error" }, displayFor)
  } else {
    info.type = "error"
    notify(info, displayFor)
  }
}

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(Notifications)
  return {
    provide: {
      toast: notifyFlexible,
    },
  }
})
