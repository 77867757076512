import { defineStore } from "pinia"

// Session stuff for the user is mostly handled by the useAuth composable,
// this exists for other global state related to the session

export const useSessionStore = defineStore({
  id: "session-store",
  // https://pinia.vuejs.org/core-concepts/state.html#typescript
  state: () => {
    return {}
  },
  actions: {
    clearRedirectPath() {
      this.setRedirectPath("")
    },
    setRedirectPath(path: string) {
      useCookie("redirect-path", { sameSite: true }).value = path
    },
  },
  getters: {
    redirectPath(): string {
      return useCookie("redirect-path", { sameSite: true }).value || ""
    },
  },
})
