import Userfront from "@userfront/core"
import { useSessionStore } from "@/stores/session"

import type { AppUser } from "@/types/types"

const USERFRONT_AVATAR_PREFIX =
  "https://res.cloudinary.com/component/image/upload/avatars/"

let userfrontInitalized: boolean = false

// get current Userfront instance
function getUserfront() {
  if (!userfrontInitalized) {
    const config = useRuntimeConfig()
    Userfront.init(config.public.userfrontAccount)
    userfrontInitalized = true
  }
  return Userfront
}

// if userfront has detected an SSO profile image, use it. If they
// are providing their default avatar images, don't use it.
function getProfileImage(user: Userfront.User) {
  if (user.image && !user.image.startsWith(USERFRONT_AVATAR_PREFIX)) {
    return user.image
  }
  return ""
}

// current user if logged in, otherwise undefined
export function useCurrentUser(): AppUser | undefined {
  const user = getUserfront().user
  if (user?.email) {
    // only expose properties we want to use
    return {
      authId: user.userId,
      email: user.email,
      name: user.name,
      profileImage: getProfileImage(user),
      createdAt: user.createdAt,
    }
  }
  return undefined
}

// Handle redirects for the user after signing up or logging in
// https://userfront.com/docs/js#handleredirect-redirect-data
function handleRedirect(_redirect: string, _data: object) {
  const sessionStore = useSessionStore()
  const redirectPath = sessionStore.redirectPath
  if (redirectPath) {
    // send the user back to where they were trying to go
    navigateTo(redirectPath)
    sessionStore.clearRedirectPath()
  } else {
    navigateTo("/streams?mode=login")
  }
}

// identify user for product analytics
function identifyUser(email: string) {
  window.ko?.identify(email) // koala
}

// clear identify for product analytics
function resetIdentifyUser() {
  window.ko?.reset() // koala
}

// primary auth toolkit
export function useAuth() {
  const userfront = getUserfront()

  function accessToken(): string | undefined {
    return userfront.tokens.accessToken
  }

  function logout() {
    userfront.logout()
    resetIdentifyUser()
  }

  return {
    accessToken,
    logout,
    user: useCurrentUser(),
    userfront,
  }
}

// login tools
export function useAuthLogin() {
  const userfront = getUserfront()

  function loginWithEmail(email: string, password: string) {
    return userfront
      .login({
        email,
        password,
        method: "password",
        handleRedirect,
      })
      .then((result) => {
        identifyUser(email)
        return result
      })
  }

  // initiate SSO login for google
  function loginWithGoogle() {
    return userfront.login({ method: "google" })
  }

  // finish SSO login for google, extract auth params from google's
  // redirect, use them to finish login
  function completeGoogleLogin() {
    return userfront
      .login({ method: "link", handleRedirect })
      .then((result) => {
        const email = userfront.user?.email
        if (email) identifyUser(email)
        return result
      })
  }

  return {
    completeGoogleLogin,
    loginWithEmail,
    loginWithGoogle,
  }
}

// password tools
export function useAuthPassword() {
  const userfront = getUserfront()

  // reset password while unauthenticated. requires uuid & token from
  // reset password link provided in email sent to user
  function resetPassword(uuid: string, token: string, password: string) {
    return userfront.updatePassword({ method: "link", uuid, token, password })
  }

  function sendPasswordResetEmail(email: string) {
    return userfront.sendResetLink(email)
  }

  return {
    resetPassword,
    sendPasswordResetEmail,
  }
}
