import { useCurrentUser } from "@/composables/auth"
import { useSessionStore } from "@/stores/session"

const UNAUTHENTICATED_ROUTES = [
  "/login",
  "/forgot-password",
  "/account-link",
  "/reset-account",
  "/invite-expired",
]

export default defineNuxtRouteMiddleware((to, from) => {
  // if logged in already, continue
  const user = useCurrentUser()
  if (user) return

  // allow unauthenticated routes
  if (UNAUTHENTICATED_ROUTES.includes(to.path)) return

  // if we are running in dev mode and a static API token has been set, bypass auth
  const config = useRuntimeConfig()
  const devMode = config.public.deploymentEnvironment === "development"
  if (devMode && config.public.apiToken) return

  // send unauthenticated users to login
  useSessionStore().setRedirectPath(from.fullPath)
  return navigateTo("/login")
})
