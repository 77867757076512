import * as Sentry from "@sentry/vue"

import type { Router } from "vue-router"
// import { BrowserTracing } from '@sentry/tracing';

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()
  const { vueApp } = nuxtApp

  if (config.public.sentryDsn) {
    Sentry.init({
      app: vueApp,
      dsn: config.public.sentryDsn,
      environment: config.public.deploymentEnvironment,
      attachProps: true,
      logErrors: true,
      release: config.public.releaseSha,
      integrations: [
        Sentry.browserTracingIntegration({ router: nuxtApp.$router as Router }),
        // Sentry.replayIntegration()
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!

      // Session Replay
      // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

      // might need this if we have to turn logErrors off
      // beforeSend(event, hint) {
      //   // Check if it is an exception, and if so, log it.
      //   if (event.exception) {
      //     // eslint-disable-next-line no-console
      //     console.error(
      //       `[Exception handled by Sentry]: (${hint.originalException})`,
      //       { event, hint }
      //     )
      //   }
      //   // Continue sending to Sentry
      //   return event
      // },
    })
  }

  // vueApp.mixin(
  //   Sentry.createTracingMixins({
  //     trackComponents: true,
  //     timeout: 2000,
  //     hooks: ["activate", "mount", "update"],
  //   })
  // )
  // Sentry.attachErrorHandler(vueApp, {
  //   logErrors: false,
  //   attachProps: true,
  //   trackComponents: true,
  //   timeout: 2000,
  //   hooks: ["activate", "mount", "update"],
  // })

  return {
    provide: {
      sentrySetContext: Sentry.setContext,
      sentrySetUser: Sentry.setUser,
      sentrySetTag: Sentry.setTag,
      sentryAddBreadcrumb: Sentry.addBreadcrumb,
      sentryCaptureException: Sentry.captureException,
    },
  }
})
