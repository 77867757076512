<script setup lang="ts">
const pageError = useError()
const statusCode =
  pageError.value && "statusCode" in pageError.value
    ? pageError.value.statusCode
    : 500
const errorText = getErrorText(statusCode)

function getErrorText(statusCode: number): {
  headline: string
  message: string
} {
  switch (statusCode) {
    case 400:
      return {
        headline: "Bad Request",
        message: "The server cannot process the request due to invalid syntax.",
      }
    case 401:
      return {
        headline: "Unauthorized",
        message: "This area is reserved for authorized users only.",
      }
    case 403:
      return {
        headline: "Forbidden",
        message: "Sorry, this area is off-limits.",
      }
    case 404:
      return {
        headline: "Page Not Found",
        message: "Oops! This page seems to be missing.",
      }
    case 500:
      return {
        headline: "Internal Server Error",
        message: "Something went wrong on our end. We're on it!",
      }
    case 503:
      return {
        headline: "Service Unavailable",
        message: "Sorry, the service is temporarily unavailable.",
      }
    default:
      return {
        headline: "Error",
        message: "An error occurred while processing your request.",
      }
  }
}

const handleError = () => clearError({ redirect: "/" })
</script>

<template>
  <div class="wrapper">
    <div class="error">
      <p class="status">{{ statusCode }}</p>
      <h1 class="headline">{{ errorText.headline }}</h1>
      <p class="message">{{ errorText.message }}</p>
      <div class="buttons">
        <a class="btn primary" href="/" @click="handleError">Back to Streams</a>
        <a class="btn" href="http://docs.streamfold.com/">View Docs</a>
        <a class="btn" href="mailto:support@streamfold.com">Contact support</a>
      </div>
    </div>
  </div>
</template>

<style scoped>
.wrapper {
  background: #0f172a;
  background: linear-gradient(to bottom, #0f172a, #202356);
  color: #a5b4fc;
  display: grid;
  height: 100%;
  padding: 0 0 64px;
  position: absolute;
  place-items: center;
  width: 100%;
}
.error {
  text-align: center;
}
.status {
  color: #818cf8;
  font-size: 24px;
  font-weight: 600;
}
.headline {
  color: #fff;
  font-size: 36px;
  margin-bottom: 8px;
}
.message {
  color: #a5b4fc;
}
.buttons {
  align-items: center;
  display: flex;
  gap: 16px;
  margin-top: 24px;
}
.btn {
  align-items: center;
  border: 1px solid #4c559a;
  border-radius: 4px;
  box-sizing: border-box;
  color: #fff;
  display: inline-flex;
  height: 36px;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  padding: 10px 12px;
  transition: color 0.3s ease;
}
.btn:hover {
  border: 1px solid #818cf8;
}
.btn.primary {
  background: #6366f1;
  border: none;
}
.btn.primary:hover {
  background: #7375f2;
}
</style>
